import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { component as Footer } from "@layout/footer"
import { component as Header } from "@layout/header"
import Layout from "@layout/no-header-footer"
import Grid from "@layout/grid"
import Jumbotron from "@components/jumbotron"
import Card from "@components/card"

class BrandsPage extends React.Component {
  // data alias
  page = this.props.data.datoCmsBrandsTruckVan
  props = this.props

  maybeCreateGroup(group, section) {
    if (group) {
      return group
    }

    return {
      categories: [],
      section
    }
  }

  getGroupedProducts() {
    const grouped = {}
    this.props.data.productCategories.nodes.forEach((node) => {
      const sectionPermalink = node.productSection.permalink
      grouped[sectionPermalink] = this.maybeCreateGroup(
        grouped[sectionPermalink],
        node.productSection
      )

      grouped[sectionPermalink].categories.push(node)
    })

    return Object.values(grouped)
  }

  getProductsSection({ categories, section }) {
    return (
      <>
        <h2 style={{ marginBottom: "35px", marginTop: "20px" }}>{section.title}</h2>
        <Grid items={`${categories.length}`} hasChildren gutter="15" layout="4">
          {categories.map((category, index) => {
            category.link = `/${section.permalink}/${category.permalink}`
            const cardData = {
              body: `<a href="/${section.permalink}">${section.title}</a>`,
              heading: category.title,
              image: {
                childImageSharp: {
                  gatsbyImageData: {
                    ...category.thumbnail.gatsbyImageData
                  }
                }
              },
              theme: "media",
              url: category.link
            }
            return <Card key={index} {...cardData} />
          })}
        </Grid>
      </>
    )
  }

  render() {
    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>
        <Header {...this.props.data.menu} />

        <div style={{ marginTop: "75px" }}>
          <Jumbotron {...this.page.jumbotron}></Jumbotron>
        </div>

        <Layout seoMetaTags={this.page.seoMetaTags}>
          <div style={{ marginBottom: "60px" }}>
            {this.getGroupedProducts().map(({ categories, section }) => {
              return this.getProductsSection({ categories, section })
            })}
          </div>
        </Layout>

        <Footer {...this.props.data.footer} />
      </>
    )
  }
}

export default BrandsPage

export const query = graphql`
  query BrandsPageTruckVanQuery($permalink: String!, $productSectionPermalinks: [String!]) {
    datoCmsBrandsTruckVan(permalink: { eq: $permalink }) {
      permalink
      title
      seoMetaTags {
        tags
      }
      jumbotron {
        buttons {
          label
          link
          theme
        }
        body
        heading
        theme
      }
    }

    ...MegaMenu
    ...Footer

    productCategories: allDatoCmsProductCategoryTruckVan(
      filter: { productSection: { permalink: { in: $productSectionPermalinks } } }
    ) {
      nodes {
        title
        permalink
        thumbnail {
          gatsbyImageData(aspectRatio: 1.5)
        }
        productSection {
          title
          permalink
        }
      }
    }
  }
`
